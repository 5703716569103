import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Layout from "../../components/layout"

const Tag = styled.div`
  position: absolute;
  bottom: 20px;
  font-size: 12px;
  padding: 0 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: rgba(30, 30, 30, 0.8);
  color: #fff;
  transform-origin: left;
  transform: 0.1s ease-in;
`

const ProjectLabel = styled.div`
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.8) 50%,
    #156ac5 50%
  );
  background-size: 200% auto;
  background-position: 100% 0;
  transition: 0.15s ease-out;
  padding: 12px 16px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  font-weight: 600;
  font-family: "Space Grotesk", monospace;
  font-size: 20px;
`
const ImageWrapper = styled.div`
  padding: 8px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`
const CardLink = styled(Link)`
  position: relative;
  display: inline-block;
  margin-right: 16px;
  border-radius: 8px;
  text-decoration: none;
  color: #444;
  width: ${({ width }) => `${width}px`};
  transition: 0.1s ease-in;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(0, 0, 0, 0.75);
  img {
  }
  &:hover {
    ${ProjectLabel} {
      color: #fff;
      background-position: 0 100%;
    }
    ${Tag} {
      background: rgb(30, 30, 30);
    }
  }
`

const GridDisplay = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 50% 50%;
  margin: 20px 0;
`

const Project = ({ to, label, children }) => {
  return (
    <CardLink to={to}>
      <ProjectLabel>{label}</ProjectLabel>
      {children}
    </CardLink>
  )
}
export default ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Lab" />
      <Header siteTitle="Lab" />
      <h4>2022</h4>
      <GridDisplay>
        <p style={{ color: "#fff" }}>To be updated</p>
      </GridDisplay>
      <h4>2021</h4>
      <GridDisplay>
        <Project to="/lab/jenga" label="Jenga">
          <ImageWrapper>
            <StaticImage
              src="../../images/jenga.png"
              alt="2021-jenga"
              placeholder="blurred"
              layout="constrained"
            />
            <Tag>Interactive Sketch</Tag>
          </ImageWrapper>
        </Project>
        <Project to="/lab/uncanny-counter-beam" label="Uncanny Counter Beam">
          <ImageWrapper>
            <StaticImage
              src="../../images/beams.png"
              alt="2021-beams"
              placeholder="blurred"
              layout="constrained"
            />
            <Tag>Interactive Sketch</Tag>
          </ImageWrapper>
        </Project>
      </GridDisplay>
      <h4>2020</h4>
      <GridDisplay>
        <Project
          to="/lab/qwerty-keyboard"
          label="Isometric Lab: Interactive QWERTY Keyboard"
        >
          <ImageWrapper>
            <StaticImage
              src="../../images/qwerty.png"
              alt="2020-qwerty"
              placeholder="blurred"
              layout="constrained"
            />
            <Tag>Interactive Sketch</Tag>
          </ImageWrapper>
        </Project>
      </GridDisplay>
      <h4>Older works</h4>
      <GridDisplay>
        <Project to="/lab/arkit" label="ARKit Translator App">
          <ImageWrapper>
            <StaticImage
              src="../../images/arkit.png"
              alt="2017-arkit"
              placeholder="blurred"
              layout="constrained"
            />
            <Tag>App</Tag>
          </ImageWrapper>
        </Project>
      </GridDisplay>
      <GridDisplay>
        <Project to="/lab/others/pikachu" label="Pikachu Volleyball">
          <ImageWrapper>
            <StaticImage
              src="../../images/pikachu.png"
              alt="2015-pikachu"
              placeholder="blurred"
              layout="constrained"
            />
            <Tag>Mini-game</Tag>
          </ImageWrapper>
        </Project>
      </GridDisplay>
    </Layout>
  )
}
